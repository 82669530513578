/* stylelint-disable selector-max-id */
@tailwind base;

/* stylelint-disable-next-line scss/at-import-partial-extension-blacklist */
@import '~stream-chat-react/dist/scss/v2/index.scss';

@layer base {
  * {
    @apply subpixel-antialiased;
  }

  html,
  body,
  #root {
    @apply min-h-full;
  }

  body {
    @apply m-0;
    @apply print:bg-white;
  }

  a {
    @apply no-underline;
    @apply text-inherit;
  }

  a:visited {
    @apply text-inherit;
  }

 .text-ltr {
    direction: ltr;
  }

  .text-rtl {
    direction: rtl;
  }

  // no hook to use tailwind to hide built-in controls
  .mapboxgl-ctrl {
    @apply print:hidden;
  }
}

// Inject component classes registered by tailwind plugins
@tailwind components;

// Custom components
@layer components {
  // todo
}

.str-chat {
  --str-chat__active-primary-color: theme('colors.carbon.orange');
  --str-chat__background-color: transparent;
  --str-chat__border-radius-circle: 3px;
  --str-chat__border-radius-circle: theme('borderRadius.full');
  --str-chat__border-radius-lg: theme('borderRadius.lg');
  --str-chat__border-radius-md: theme('borderRadius.md');
  --str-chat__border-radius-sm: theme('borderRadius.sm');
  --str-chat__border-radius-xs: theme('borderRadius.xs');
  --str-chat__box-shadow-color: theme('colors.darken.700');
  --str-chat__danger-color: theme('colors.red.500');
  --str-chat__disabled-color: theme('colors.lighten.200');
  --str-chat__font-family: 'Poppins', sans-serif;
  --str-chat__headline-text: 1.5 rem/1.5 rem 'Russo One', 'Poppins', sans-serif;
  --str-chat__headline2-text: 1.8 rem/1.8 rem 'Russo One', 'Poppins', sans-serif;
  --str-chat__info-color: theme('colors.carbon.blue');
  --str-chat__message-highlight-color: theme('colors.yellow.500');
  --str-chat__on-disabled-color: theme('colors.white');
  --str-chat__on-primary-color: theme('colors.white');
  --str-chat__on-unread-badge-color: theme('colors.white');
  --str-chat__opaque-surface-background-color:theme('colors.darken.800');
  --str-chat__opaque-surface-text-color: theme('colors.white');
  --str-chat__overlay-color: theme('colors.darken.800');
  --str-chat__primary-color-low-emphasis: transparent;
  --str-chat__primary-color: theme('colors.carbon.orange');
  --str-chat__primary-surface-color-low-emphasis: theme('colors.orange.100');
  --str-chat__primary-surface-color: theme('colors.orange.300');
  --str-chat__secondary-background-color: theme('colors.zinc.800');
  --str-chat__secondary-overlay-color: theme('colors.darken.500');
  --str-chat__secondary-overlay-text-color: theme('colors.white');
  --str-chat__secondary-surface-color: theme('colors.zinc.700');
  --str-chat__surface-color: theme('colors.zinc.500');
  --str-chat__surface-color: theme('colors.zinc.800');
  --str-chat__tertiary-surface-color: theme('colors.zinc.900');
  --str-chat__text-color: theme('colors.white');
  --str-chat__text-low-emphasis-color: theme('colors.lighten.600');
  --str-chat__unread-badge-color: theme('colors.carbon.blue');
  --str-chat__own-message-bubble-background-color: theme('colors.zinc.600');

  // Actions
  .str-chat__message {
    .str-chat__message-options {
      @apply hidden;
    }

    &:hover .str-chat__message-options {
      @apply flex;
    }
  }

  // Avatar
  .str-chat__message .str-chat__message-sender-avatar,
  .str-chat__quoted-message-preview .str-chat__message-sender-avatar {
    align-self: start;
  }
  
  // Date separator
  .str-chat__date-separator {
    @apply flex;
    @apply justify-center;
  }

  .str-chat__date-separator-line {
    @apply hidden;
  }

  .str-chat__date-separator-date {
    @apply bg-zinc-500;
    @apply text-white;
    @apply text-xs;
    @apply px-4;
    @apply rounded-full;
  }

  // Reactions
  .str-chat__message-reactions .str-chat__message-reaction {
    @apply rounded-md;
  }

  .str-chat__message-reaction-count {
    @apply text-white;
  }

  .str-chat__message-reaction-selector {
    @apply relative;
  }

  .str-chat__message-reactions-list {
    @apply mt-0;
  }

  // lightbox
  .str-chat__modal--open {
    z-index: 1201;
  }

  // message viewport
  .str-chat__message-list-scroll > div {
    @apply pt-24;
  }
}

// has to come before tailwind utilities
.unset {
  all: unset;
}

// Inject tailwind utility classes
@tailwind utilities;

// Custom utilities
@layer utilities {
  .bg-rainbow {
    /* stylelint-disable-next-line color-named */
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  }

  .text-rainbow {
    /* stylelint-disable-next-line color-named */
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 1px #fff;
  }
}
